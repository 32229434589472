export default defineNuxtRouteMiddleware(() => {
  const settings = useSettings();

  const allowCreditTopup = settings.value.allow_credit_topup;

  if (allowCreditTopup === false) {
    useNuxtApp().$toast(
      "La recarga de crédito está deshabilitada en este momento.",
    );

    return navigateTo(
      {
        name: "profile",
      },
      { replace: true },
    );
  }
});
